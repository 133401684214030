import React, { useState, useEffect, useRef } from 'react'

// css
import { WrapSection1 } from './index.styles'

// data
import { data } from './data'

//img
import btn_view_details from '../../assets/images/PNG/btn_view_details.png'
import google_play from '../../assets/images/PNG/Google_Play_Badge.png'
import app_store from '../../assets/images/PNG/App_Store_Badge_VN.png'

import text_title from '../../assets/images/PNG/text_section1.png'
import text_title_tablet from '../../assets/images/PNG/text_section1_table.png'

// component
import Header from '../Header'
import Portal from '../Control/Portal'
import Popup50K from '../Control/Popup50K'

export default function Section1() {
    let timer = null

    const indexSelectedRef = useRef(0)
    const titleRef = useRef(null)
    const slideBarRef = useRef(null)

    const [indexSelected, setIndexSelected] = useState(0)
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        _renderUI()
        window.addEventListener("resize", _handleResize)
        timer = setInterval(() => {
            _handleSlide()
        }, 5000);
        return () => {
            window.removeEventListener("resize", _handleResize)
            clearInterval(timer)
        }
    }, [])

    const _handleResize = (e) => {
        _renderUI()
    }

    const _handleSlide = () => {
        indexSelectedRef.current++
        if (indexSelectedRef.current > data.length - 1) indexSelectedRef.current = 0
        setIndexSelected(indexSelectedRef.current)
    }

    const _renderUI = () => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        if (!titleRef.current) {
            if (vw >= 425 && vw < 768) {
                titleRef.current.src = `${text_title_tablet}`
            }
            else {
                titleRef.current.src = `${text_title}`
            }
        }
        const image1 = document.getElementById('slide-1')
        let list = document.getElementsByClassName('image-slide-sect1')
        if(!image1 || !slideBarRef.current || !list || list.length === 0) return
        if(image1.clientWidth < slideBarRef.current.clientWidth){
            if(Array.from(list)[0].style.height === '100%') return
            Array.from(list).forEach(dom => {
                dom.style.height = '100%'
                dom.style.width = 'unset'
            });
        }
        else{
            if(image1.clientHeight > slideBarRef.current.clientHeight){
                Array.from(list).forEach(dom => {
                    dom.style.height = `${slideBarRef.current.clientHeight}px`
                    dom.style.width = 'unset'
                });
            }
            else{
                if(Array.from(list)[0].style.width === '100%') return
                Array.from(list).forEach(dom => {
                    dom.style.height = 'unset'
                    dom.style.width = '100%'
                });
            }
        }
    }

    return (
        <WrapSection1>
            <Header />
            <div className='wrap-content'>
                <img className='block-title' src={text_title} alt="text" ref={titleRef} />
                <div className='block-control'>
                    <div className='wrap-badge'>
                        <a href="https://play.google.com/store/apps/details?id=vn.wee.facepay" target="_blank" >
                            <img src={google_play} alt="google_play" />
                        </a>
                        <a href="https://apps.apple.com/vn/app/facepay/id1529422054" target="_blank">
                            <img  src={app_store} alt="app_store" />
                        </a>
                    </div>
                    {/* <img className='btn-view' src={btn_view_details} alt="image" onClick={() => setIsShowPopup(true)} /> */}
                </div>
                <div className='block-slide-image'>
                    <div className='slide-bar' ref={slideBarRef}>
                        {
                            data.map((item, i) => {
                                return (
                                    <img className={`image-slide-sect1 ${data[indexSelected].key === item.key ? 'active' : 'inactive'}`} 
                                        src={item.image} key={i}
                                        id={item.key} />
                                )
                            })
                        }
                    </div>
                </div>
                {
                    isShowPopup &&
                    <Portal>
                        <Popup50K setIsShowPopup={setIsShowPopup} />
                    </Portal>
                }
            </div>
        </WrapSection1>
    )
}
