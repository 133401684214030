import React from 'react'

const IcClose = (props) => {
    return (
        <svg {...props} width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={48} height={48} rx={24} fill="white" fillOpacity="0.15" />
            <path d="M31.1695 16.7834L16.7836 31.1694" stroke="white" strokeWidth="1.79825" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.7836 16.7834L31.1695 31.1694" stroke="white" strokeWidth="1.79825" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcClose