import styled from "styled-components"

const WrapMain = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
`

const WrapSection = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    min-height: 600px;
    align-items: center;
`

const OverlayFullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    width: 1280px;
    height: 900px;
    background: ${ props => props.notUseOverlay ? 'transparent' : '#22222280' };
    z-index: 6;

    .wrap-inner-screen {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export {
    WrapMain,
    WrapSection,
    OverlayFullScreen
}