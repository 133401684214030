import React, { useState, useEffect, useRef } from 'react'

// css
import { WrapPage3 } from './index.styles'

//img
import text_section5 from '../../../assets/images/PNG/text_section5.png'
import text_section3_page3_tablet from '../../../assets/images/PNG/text_section3_page3_tablet.png'
import google_play from '../../../assets/images/PNG/Google_Play_Badge.png'
import app_store from '../../../assets/images/PNG/App_Store_Badge_VN.png'
import btn_view_details from '../../../assets/images/PNG/btn_view_details.png'

//data
import { dataLinkSocial, dataLogoBank } from './data'

//component
import Portal from '../../Control/Portal'
import Popup50K from '../../Control/Popup50K'
import IcFacebook from '../../../assets/images/SVG/IcFacebook'
import IcInstagram from '../../../assets/images/SVG/IcInstagram'
import IcLinkedin from '../../../assets/images/SVG/IcLinkedin'
import IcZalo from '../../../assets/images/SVG/IcZalo'
import IcYoutube from '../../../assets/images/SVG/IcYoutube'
import IcTiktok from '../../../assets/images/SVG/IcTiktok'

export default function Page3() {
  const wrapLogoBankRef = useRef(null)
  const titleRef = useRef(null)

  const [isShowPopup, setIsShowPopup] = useState(false)
  const [listLogoBank, setListLogoBank] = useState(dataLogoBank)

  useEffect(() => {
    _renderUI()
    window.addEventListener("resize", _handleResize)
    return () => {
      window.removeEventListener("resize", _handleResize)
    }
  }, [])

  const _handleResize = (e) => {
    _renderUI()
  }

  const _renderUI = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    console.log(wrapLogoBankRef.current.clientWidth)
    switch (wrapLogoBankRef.current.clientWidth) {
      case 1228: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        list.splice(32, 0, { type: 'blank' });
        setListLogoBank(list)
      }
        break;
      case 1080: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        list.splice(35, 0, { type: 'blank' }, { type: 'blank' });
        setListLogoBank(list)
      }
        break;
      case 928: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        list.splice(36, 0, { type: 'blank' }, { type: 'blank' });
        setListLogoBank(list)
      }
        break;
      case 776: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        list.splice(35, 0, { type: 'blank' });
        setListLogoBank(list)
      }
        break;
      case 624: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        list.splice(36, 0, { type: 'blank' });
        setListLogoBank(list)
      }
        break
      default: {
        let list = listLogoBank.filter((item) => item.type !== 'blank')
        setListLogoBank(list)
      }
        break;
    }
    if (!titleRef.current) return
    if (vw <= 768) {
      titleRef.current.src = `${text_section3_page3_tablet}`
    }
    else {
      titleRef.current.src = `${text_section5}`
    }
  }

  return (
    <WrapPage3>
      <img className='block-title-sect5' src={text_section5} alt="text" ref={titleRef} />
      <div className='wrap-logo-bank' ref={wrapLogoBankRef}>
        {
          listLogoBank.map((item, index) =>
            item.type === 'blank' ? <div key={index} />
              :
              <img className='logo-bank' src={item.logo} key={index} alt="logo" />
          )
        }
      </div>
      <div className='block-control'>
        <div className='wrap-badge'>
          <a href="https://play.google.com/store/apps/details?id=vn.wee.facepay" target="_blank" >
            <img src={google_play} alt="google_play" />
          </a>
          <a href="https://apps.apple.com/vn/app/facepay/id1529422054" target="_blank">
            <img src={app_store} alt="app_store" />
          </a>
        </div>
        {/* <img className='btn-view' src={btn_view_details} alt="image" onClick={() => setIsShowPopup(true)} /> */}
      </div>
      <div className='block-link'>
        <p className='title-link'>Liên kết với Facepay</p>
        <div className="icon-wrap">
          <a className="icon" href="https://www.facebook.com/facepayvietnam" target="_blank"><IcFacebook /></a>
          <a className="icon" href="https://www.instagram.com/facepay.vn/" target="_blank"><IcInstagram /></a>
          <a className="icon" href="https://www.tiktok.com/@facepayvietnam" target="_blank"><IcTiktok /></a>
          <a className="icon" href="https://www.youtube.com/channel/UCctGm-4I-iyuA092uzG_Cmg" target="_blank"><IcYoutube /></a>
          <a className="icon" href="https://www.linkedin.com/company/facepay-vi%E1%BB%87t-nam/" target="_blank"><IcLinkedin /></a>
          <a className="icon" href="https://zalo.me/3836098346553639514" target="_blank"><IcZalo /></a>
        </div>
      </div>
      {
        isShowPopup &&
        <Portal>
          <Popup50K setIsShowPopup={setIsShowPopup} />
        </Portal>
      }
    </WrapPage3>
  )
}
