import styled from "styled-components";

const WrapHeader = styled.div`
    width: 100%;
    height: 80px;
    position: absolute;
    padding: 24px 0;
    padding-left: calc(24px + (72 - 24) * ((100vw - 425px) / (1440 - 425)));
    box-sizing: border-box;
`

export {
    WrapHeader
}