import React from 'react'

const IcFacebook = (props) => {
    return (
        <svg {...props} width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_5020_94376)">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.4435 10.0386C19.4614 10.5566 15.1024 13.2627 12.4499 17.4845C9.37467 22.3793 9.23868 28.7578 12.1031 33.7556C13.6108 36.3861 15.8222 38.5489 18.4325 39.9457C20.6534 41.1342 23.1052 41.7897 25.4074 41.8106L26.2476 41.8182L26.2635 35.6389L26.2794 29.4597H24.5676H22.8558V27.4684V25.4771L24.5517 25.4605L26.2476 25.444L26.2828 23.3583C26.3148 21.4652 26.3308 21.2266 26.4553 20.7745C26.9292 19.0543 28.0727 17.91 29.7951 17.432C30.243 17.3077 30.4396 17.2936 31.7555 17.2918C32.5599 17.2907 33.4911 17.313 33.8248 17.3414L34.4316 17.393V19.1878V20.9825L32.9535 21.0033C31.484 21.0239 31.4736 21.025 31.1542 21.1933C30.4685 21.5545 30.3863 21.8439 30.3863 23.8974V25.4751H32.3467C33.425 25.4751 34.307 25.4961 34.3069 25.5218C34.3068 25.5474 34.1948 26.4212 34.058 27.4635C33.9212 28.5057 33.8093 29.3812 33.8093 29.4091C33.8093 29.4369 33.0391 29.4597 32.0978 29.4597H30.3863V35.3432C30.3863 38.5791 30.3972 41.2267 30.4105 41.2267C30.5292 41.2267 32.0105 40.6663 32.5713 40.4093C34.4432 39.5513 35.8965 38.5182 37.3889 36.9849C38.6873 35.651 39.4933 34.4977 40.3201 32.7906C41.0552 31.2728 41.5567 29.5805 41.7868 27.841C41.8892 27.0666 41.8892 24.6703 41.7869 23.9497C41.2637 20.2683 39.7441 17.1712 37.224 14.6502C34.7556 12.1807 31.6786 10.6407 28.1769 10.1221C27.4135 10.0089 25.2039 9.95962 24.4435 10.0386Z" fill="#85E0FF" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4435 10.0386C19.4614 10.5566 15.1024 13.2627 12.4499 17.4845C9.37467 22.3793 9.23868 28.7578 12.1031 33.7556C13.6108 36.3861 15.8222 38.5489 18.4325 39.9457C20.6534 41.1342 23.1052 41.7897 25.4074 41.8106L26.2476 41.8182L26.2635 35.6389L26.2794 29.4597H24.5676H22.8558V27.4684V25.4771L24.5517 25.4605L26.2476 25.444L26.2828 23.3583C26.3148 21.4652 26.3308 21.2266 26.4553 20.7745C26.9292 19.0543 28.0727 17.91 29.7951 17.432C30.243 17.3077 30.4396 17.2936 31.7555 17.2918C32.5599 17.2907 33.4911 17.313 33.8248 17.3414L34.4316 17.393V19.1878V20.9825L32.9535 21.0033C31.484 21.0239 31.4736 21.025 31.1542 21.1933C30.4685 21.5545 30.3863 21.8439 30.3863 23.8974V25.4751H32.3467C33.425 25.4751 34.307 25.4961 34.3069 25.5218C34.3068 25.5474 34.1948 26.4212 34.058 27.4635C33.9212 28.5057 33.8093 29.3812 33.8093 29.4091C33.8093 29.4369 33.0391 29.4597 32.0978 29.4597H30.3863V35.3432C30.3863 38.5791 30.3972 41.2267 30.4105 41.2267C30.5292 41.2267 32.0105 40.6663 32.5713 40.4093C34.4432 39.5513 35.8965 38.5182 37.3889 36.9849C38.6873 35.651 39.4933 34.4977 40.3201 32.7906C41.0552 31.2728 41.5567 29.5805 41.7868 27.841C41.8892 27.0666 41.8892 24.6703 41.7869 23.9497C41.2637 20.2683 39.7441 17.1712 37.224 14.6502C34.7556 12.1807 31.6786 10.6407 28.1769 10.1221C27.4135 10.0089 25.2039 9.95962 24.4435 10.0386Z" fill="white" />
            <defs>
                <filter id="filter0_f_5020_94376" x="0.500001" y="0.454545" width="50.9091" height="50.909" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4.77273" result="effect1_foregroundBlur_5020_94376" />
                </filter>
            </defs>
        </svg>
    )
}

export default IcFacebook