import React from 'react'

// css
import { WrapSection2 } from './index.styles'

// data
import { data } from '../data'

export default function Laptop() {
  return (
    <WrapSection2>
      <div className='wrap-image-content'>
        {
          data.map((item, i) => {
            return (
              <div className='image-bound' key={i}>
                <div className={`image`}>
                  <img src={item.card.image} alt="image" />
                </div>
                <div className={`gif`}>
                  <img className='text' src={item.card.text} alt="text" />
                  <img src={item.card.imageGif} alt="imageGif" />
                </div>
              </div>
            )
          })
        }
      </div>
    </WrapSection2>
  )
}
