import styled from "styled-components"
import { WrapSection } from "../../index.styles"

const WrapPage2 = styled(WrapSection)`   
    height: fit-content;
    justify-content: center;
    padding-top: calc(24px + (128 - 24) * ((100vw - 375px) / (1440 - 375)));
    padding-bottom: calc(64px + (128 - 64) * ((100vw - 375px) / (1440 - 375)));
    .block-title-sect4{
        width: calc(314px + (596 - 314) * ((100vw - 375px) / (786 - 375)));
    }
    .wrap-content{
        display: flex;
        flex-direction: column;
        margin-top: 64px;
        padding: 0 calc(28px + (72 - 28) * ((100vw - 375px) / (768 - 375)));
        .content{
            display: flex;
            align-items: start;
            margin-bottom: 24px;
            max-width: 856px;
            :last-child{
                margin-bottom: 0;
            }
            .icon{
                flex-shrink: 0;
                margin-top: 3px;
            }
            .text{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (768 - 375)));
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #FFFFFF;
                text-shadow: 0.582111px 0.582111px 0px rgba(0, 0, 0, 0.25);
                padding-left: 8px;               
                span{                   
                    font-weight: 700;                    
                    padding: 0 5px;
                }
            }
        }
    }

    @media only screen and (min-width: 769px) {
        .block-title-sect4{
            width: calc(700px + (1093 - 700) * ((100vw - 768px) / (1440 - 768)));
        }
        .wrap-content{
            padding: 0 calc(72px + (292 - 72) * ((100vw - 768px) / (1440 - 768)));
            .content{
                .text{
                    font-size: 24px;
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        .block-title-sect4{
            width: 1093px;
        }
        .wrap-content{
            padding: 0 292px;
        }
    }
`

export {
    WrapPage2
}