import React from 'react'

const IcYoutube = (props) => {
    return (
        <svg {...props} width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_5020_94385)">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.8986 10.0386C19.9201 10.5563 15.5569 13.2657 12.9064 17.4854C9.14883 23.4677 9.85034 31.3707 14.601 36.5755C17.2304 39.4563 20.5188 41.1786 24.4623 41.7403C25.1985 41.8451 27.6451 41.8438 28.4138 41.7382C32.0154 41.243 35.241 39.6456 37.6941 37.1424C38.4992 36.3208 38.6707 36.1243 39.2048 35.4121C40.7962 33.2896 41.8381 30.7022 42.2401 27.8743C42.3442 27.1417 42.3442 24.6837 42.24 23.9515C41.9718 22.0663 41.4842 20.4602 40.7019 18.8849C39.8729 17.2159 39.0223 16.026 37.6983 14.6835C35.2422 12.193 32.167 10.6459 28.6316 10.1221C27.8682 10.0089 25.6589 9.95961 24.8986 10.0386ZM29.8762 19.0012C33.7084 19.1404 34.5265 19.2869 35.2487 19.9628C35.8436 20.5196 36.0899 21.2618 36.2628 23.0175C36.3693 24.0992 36.3704 27.5611 36.2646 28.6838C36.1051 30.3766 35.9489 31.0201 35.5777 31.5144C35.0607 32.2029 34.5473 32.4606 33.4067 32.6039C30.3392 32.9896 21.4379 32.9526 19.0656 32.5444C17.849 32.335 17.0788 31.609 16.8194 30.4273C16.522 29.0727 16.392 26.2115 16.528 24.0138C16.686 21.4576 16.8697 20.7265 17.529 20.029C18.0942 19.431 18.6305 19.2628 20.4174 19.1237C22.6074 18.953 26.9889 18.8963 29.8762 19.0012ZM24.4312 25.9129V28.9042L24.732 28.7317C28.5556 26.5399 29.592 25.9387 29.592 25.9129C29.592 25.8871 28.5556 25.2859 24.732 23.0941L24.4312 22.9216V25.9129Z" fill="#85E0FF" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.8986 10.0386C19.9201 10.5563 15.5569 13.2657 12.9064 17.4854C9.14883 23.4677 9.85034 31.3707 14.601 36.5755C17.2304 39.4563 20.5188 41.1786 24.4623 41.7403C25.1985 41.8451 27.6451 41.8438 28.4138 41.7382C32.0154 41.243 35.241 39.6456 37.6941 37.1424C38.4992 36.3208 38.6707 36.1243 39.2048 35.4121C40.7962 33.2896 41.8381 30.7022 42.2401 27.8743C42.3442 27.1417 42.3442 24.6837 42.24 23.9515C41.9718 22.0663 41.4842 20.4602 40.7019 18.8849C39.8729 17.2159 39.0223 16.026 37.6983 14.6835C35.2422 12.193 32.167 10.6459 28.6316 10.1221C27.8682 10.0089 25.6589 9.95961 24.8986 10.0386ZM29.8762 19.0012C33.7084 19.1404 34.5265 19.2869 35.2487 19.9628C35.8436 20.5196 36.0899 21.2618 36.2628 23.0175C36.3693 24.0992 36.3704 27.5611 36.2646 28.6838C36.1051 30.3766 35.9489 31.0201 35.5777 31.5144C35.0607 32.2029 34.5473 32.4606 33.4067 32.6039C30.3392 32.9896 21.4379 32.9526 19.0656 32.5444C17.849 32.335 17.0788 31.609 16.8194 30.4273C16.522 29.0727 16.392 26.2115 16.528 24.0138C16.686 21.4576 16.8697 20.7265 17.529 20.029C18.0942 19.431 18.6305 19.2628 20.4174 19.1237C22.6074 18.953 26.9889 18.8963 29.8762 19.0012ZM24.4312 25.9129V28.9042L24.732 28.7317C28.5556 26.5399 29.592 25.9387 29.592 25.9129C29.592 25.8871 28.5556 25.2859 24.732 23.0941L24.4312 22.9216V25.9129Z" fill="white" />
            <defs>
                <filter id="filter0_f_5020_94385" x="0.954545" y="0.454545" width="50.9091" height="50.909" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4.77273" result="effect1_foregroundBlur_5020_94385" />
                </filter>
            </defs>
        </svg>
    )
}

export default IcYoutube