// image
import terminal_1 from '../../assets/images/PNG/terminal_1.png'
import terminal_2 from '../../assets/images/PNG/terminal_2.png'
import terminal_3 from '../../assets/images/PNG/terminal_3.png'
import terminal_4 from '../../assets/images/PNG/terminal_4.png'
import terminal_5 from '../../assets/images/PNG/terminal_5.png'

export const data = [
    {
        key: "slide-1",
        image: terminal_1,
        color: 'red'
    },
    {
        key: "slide-2",
        image: terminal_2,
        color: 'green'
    },
    {
        key: "slide-3",
        image: terminal_3,
        color: 'blue'
    },
    {
        key: "slide-4",
        image: terminal_4,
        color: 'yellow'
    },
    {
        key: "slide-5",
        image: terminal_5,
        color: 'pink'
    }
]