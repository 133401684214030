import React, { useState, useEffect } from 'react'

// css
import { WrapSection2 } from './index.styles'

// data
import { data } from '../data'

// image
import IcArrowCircle from '../../../assets/images/SVG/IcArrowCircle';

const slide_main_width = 288;
let timer = null

export default function Tablet() {
  const _items = data.slice()  

  const length = _items.length;
  _items.push(..._items);

  const [isResize, setIsResize] = useState(false)

  useEffect(() => {
    _renderCarousel()
    window.addEventListener("resize", _handleResize)
    return () => {
        window.removeEventListener("resize", _handleResize)
    }
  }, [])

  useEffect(() => {
    if(isResize){
      _renderCarousel()
      setIsResize(false)
    }
    return () => {
      
    }
  }, [isResize])
  

  const _handleResize = (e) => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      setIsResize(true)
    }, 100);
  }

  const sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const _renderCarousel = () => {
    return <Carousel />
  }

  const createItem = (position, idx) => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    let widthItem = (vw - slide_main_width) / 4
    let positionX = position * (vw - slide_main_width) / 4
    if(position > 5){
      let delta = slide_main_width - widthItem
      positionX += delta
    }
    const item = {
      styles: {
        transform:`translateX(${positionX}px)`,
        width: `${widthItem}px`,
        zIndex: 0
      },
      card: _items[idx].card,
    };

    switch (position) {
      case length - 1:
      case length - 2:
      case length + 1:
      case length + 2:
          item.styles = {...item.styles};
          break;
      case length:
          item.styles = {...item.styles, width: `${slide_main_width}px`, zIndex: 1}
          break;
      default:
          item.styles = {...item.styles, opacity: 0};
          break;
    }

      return item;
  };

  const CarouselSlideItem = ({pos, idx, activeIdx}) => {
      const item = createItem(pos, idx, activeIdx);

      return (
        <div className='image-bound' style={item.styles}>
          <div className={`image`} style={{opacity: pos===length?0:1}}>
            <img src={item.card.image} alt="image" />
          </div>
          <div className={`gif`} style={{opacity: pos===length?1:0}}>
            <img className='text' src={item.card.text} alt="text" style={{zIndex: pos===length?1:0}} />
            <img src={item.card.imageGif} alt="imageGif" />
          </div>
        </div>
      );
  };

  const keys = Array.from(Array(_items.length).keys());

  const Carousel = ({children}) => {
    const [items, setItems] = useState(keys);
    const [isTicking, setIsTicking] = useState(false);
    const [activeIdx, setActiveIdx] = useState(0);
    const bigLength = items.length;

    const prevClick = (jump = 1) => {
      if (!isTicking) {
        setIsTicking(true);
        setItems((prev) => {
          return prev.map((_, i) => prev[(i + jump) % bigLength]);
        });
      }
    };

    const nextClick = (jump = 1) => {
      if (!isTicking) {
        setIsTicking(true);
        setItems((prev) => {
          return prev.map(
            (_, i) => prev[(i - jump + bigLength) % bigLength],
          );
        });
      }
    };

    useEffect(() => {
      if (isTicking) sleep(500).then(() => setIsTicking(false));
    }, [isTicking]);

    useEffect(() => {
      setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
    }, [items]);
    
    return (
      <div className="carousel__inner">
        <div className='left-overlay'>
          <IcArrowCircle className='arrow' onClick={() => prevClick()}/>
        </div>
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        <div className="right-overlay">
          <IcArrowCircle className='arrow' onClick={() => nextClick()}/>
        </div>
      </div>
    );
  };

  return (
    <WrapSection2>
        {_renderCarousel()}
    </WrapSection2>
  )
}
