import React, { useRef, useEffect } from 'react'

// css
import { WrapPage2 } from './index.styles'

//img
import text_section4 from '../../../assets/images/PNG/text_section4.png'
import text_section3_page2_tablet from '../../../assets/images/PNG/text_section3_page2_tablet.png'
import IcArtboard from '../../../assets/images/SVG/IcArtBoard'

//data
import { dataPage2Section3 } from './data'

export default function Page2() {

  const titleRef = useRef(null)

  useEffect(() => {
    _renderUI()
    window.addEventListener("resize", _handleResize)
    return () => {
      window.removeEventListener("resize", _handleResize)
    }
  }, [])

  const _handleResize = (e) => {
    _renderUI()
  }

  const _renderUI = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    if(!titleRef.current) return
    if(vw <= 768){
        titleRef.current.src = `${text_section3_page2_tablet}`
    }
    else{
        titleRef.current.src = `${text_section4}`
    }
  }

  return (
    <WrapPage2>
      <img className='block-title-sect4' src={text_section4} alt="text" ref={titleRef}/>
      <div className='wrap-content'>
        {
          dataPage2Section3.map((item, index) =>
            <div className='content' key={index}>
              <IcArtboard className='icon' />
              <div className='text' >
                {item.text1}
                <span>{item.span1}</span>
                {item.text2}
                <span>{item.span2}</span>
              </div>
            </div>
          )
        }
      </div>
    </WrapPage2>
  )
}
