//img
import vietcombank from '../../../assets/images/PNG/LogoBank/vietcombank.png'
import vib from '../../../assets/images/PNG/LogoBank/vib.png'
import MBB from '../../../assets/images/PNG/LogoBank/MBB.png'
import vietinbank from '../../../assets/images/PNG/LogoBank/vietinbank.png'
import hdbank from '../../../assets/images/PNG/LogoBank/hdbank.png'
import NCB from '../../../assets/images/PNG/LogoBank/NCB.png'
import msb from '../../../assets/images/PNG/LogoBank/msb.png'
import vietabank from '../../../assets/images/PNG/LogoBank/vietabank.png'
import gpbank from '../../../assets/images/PNG/LogoBank/gpbank.png'
import OCEAN from '../../../assets/images/PNG/LogoBank/OCEAN.png'
import bacabank from '../../../assets/images/PNG/LogoBank/bacabank.png'
import abbank from '../../../assets/images/PNG/LogoBank/abbank.png'
import bidv from '../../../assets/images/PNG/LogoBank/bidv.png'
import shb from '../../../assets/images/PNG/LogoBank/shb.png'
import baovietbank from '../../../assets/images/PNG/LogoBank/baovietbank.png'
import tpbank from '../../../assets/images/PNG/LogoBank/tpbank.png'
import AGRB from '../../../assets/images/PNG/LogoBank/AGRB.png'
import scbbank from '../../../assets/images/PNG/LogoBank/scbbank.png'
import kienlongbank from '../../../assets/images/PNG/LogoBank/kienlongbank.png'
import seabank from '../../../assets/images/PNG/LogoBank/seabank.png'
import vietbank from '../../../assets/images/PNG/LogoBank/vietbank.png'
import publicbank from '../../../assets/images/PNG/LogoBank/publicbank.png'
import NAMA from '../../../assets/images/PNG/LogoBank/NAMA.png'
import pvcombank from '../../../assets/images/PNG/LogoBank/pvcombank.png'
import saigonbank from '../../../assets/images/PNG/LogoBank/saigonbank.png'
import techcombank from '../../../assets/images/PNG/LogoBank/techcombank.png'
import acb from '../../../assets/images/PNG/LogoBank/acb.png'
import sacombank from '../../../assets/images/PNG/LogoBank/sacombank.png'
import vpbank from '../../../assets/images/PNG/LogoBank/vpbank.png'
import OCB from '../../../assets/images/PNG/LogoBank/OCB.png'
import pgbank from '../../../assets/images/PNG/LogoBank/pgbank.png'
import UOB from '../../../assets/images/PNG/LogoBank/UOB.png'
import idovinabank from '../../../assets/images/PNG/LogoBank/idovinabank.png'
import WIB from '../../../assets/images/PNG/LogoBank/WIB.png'
import exmimbank from '../../../assets/images/PNG/LogoBank/exmimbank.png'
import shinhan from '../../../assets/images/PNG/LogoBank/shinhan.png'
import vietcapital from '../../../assets/images/PNG/LogoBank/vietcapital.png'
import vrb from '../../../assets/images/PNG/LogoBank/vrb.png'

export const dataLogoBank = [
    {
        type: 'logo',
        logo: vietcombank
    },
    {
        type: 'logo',
        logo: vib
    },
    {
        type: 'logo',
        logo: MBB
    },
    {
        type: 'logo',
        logo: vietinbank
    },
    {
        type: 'logo',
        logo: hdbank
    },
    {
        type: 'logo',
        logo: NCB
    },
    {
        type: 'logo',
        logo: msb
    },
    {
        type: 'logo',
        logo: vietabank
    },
    {
        type: 'logo',
        logo: gpbank
    },
    {
        type: 'logo',
        logo: OCEAN
    },
    {
        type: 'logo',
        logo: bacabank
    },
    {
        type: 'logo',
        logo: abbank
    },
    {
        type: 'logo',
        logo: bidv
    },
    {
        type: 'logo',
        logo: shb
    },
    {
        type: 'logo',
        logo: baovietbank
    },
    {
        type: 'logo',
        logo: tpbank
    },
    {
        type: 'logo',
        logo: AGRB
    },
    {
        type: 'logo',
        logo: scbbank
    },
    {
        type: 'logo',
        logo: kienlongbank
    },
    {
        type: 'logo',
        logo: seabank
    },
    {
        type: 'logo',
        logo: vietbank
    },
    {
        type: 'logo',
        logo: publicbank
    },
    {
        type: 'logo',
        logo: NAMA
    },
    {
        type: 'logo',
        logo: pvcombank
    },
    {
        type: 'logo',
        logo: saigonbank
    },
    {
        type: 'logo',
        logo: techcombank
    },
    {
        type: 'logo',
        logo: acb
    },
    {
        type: 'logo',
        logo: sacombank
    },
    {
        type: 'logo',
        logo: vpbank
    },
    {
        type: 'logo',
        logo: OCB
    },
    {
        type: 'logo',
        logo: pgbank
    },
    {
        type: 'logo',
        logo: UOB
    },
    {
        type: 'logo',
        logo: idovinabank
    },
    {
        type: 'logo',
        logo: WIB
    },
    {
        type: 'logo',
        logo: exmimbank
    },
    {
        type: 'logo',
        logo: shinhan
    },
    {
        type: 'logo',
        logo: vietcapital
    },
    {
        type: 'logo',
        logo: vrb
    }
]
