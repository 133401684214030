import styled from "styled-components"
import { WrapSection } from "../../index.styles"

//img
import bg_Section1 from '../../../assets/images/PNG/bg_Section1.png'

const WrapSection2 = styled(WrapSection)`
    --slide-count: 5;
    --slide-main-width: 288px;
    --slide-width: calc((100vw - var(--slide-main-width)) / 4);
    --card-height: 100%;
    background-image:url(${bg_Section1});
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
    height: 100vh;
    align-items: center;
    display: flex;    
    justify-content: center;
    position: relative;
    width: 100%;
    ul {
        list-style-type: none;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    /* left: 50%;
    transform: translateX(-50%); */

    .carousel__inner {
        height: 100%;
        position: relative;
        width: calc(var(--slide-width) * 4 + var(--slide-main-width));
    }

    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .carousel__slide-list {
        height: 100%;
        left: 50%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        /* width: calc((var(--slide-count) + 0.5) * var(--slide-width) * 2); */
        width: calc(var(--slide-width) * 10 + var(--slide-main-width));
    }

    .image-bound {
        display: inline-block;
        height: var(--card-height);
        margin: 0;
        position: absolute;
        transition: all 0.3s;
        width: var(--slide-width);
        .text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: unset;
            opacity: 1;
            z-index: 2;
        }
        img, .image, .gif {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            object-fit: cover;
        }
    }

    .image {
        /* cursor: zoom-in; */
        display: flex;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: 100%;
            object-fit: cover;
            transition: all 0.5s ease;
            width: 100%;
        }

        &::after {
            align-items: center;
            background: rgba(black, 0.5);
            color: white;
            content: 'read more';
            display: flex;
            height: 100%;
            justify-content: center;
            opacity: 0;
            position: absolute;
            transition: all 0.5s ease;
            width: 100%;
        }

        &:hover {
            &::after {
                /* opacity: 1; */
            }

            img {
                /* transform: scale(1.3); */
            }
        }
    }

    .left-overlay, .right-overlay{
        width: calc(var(--slide-width) * 2);
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        .arrow{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            left: calc(8px + (36 - 8) * ((100vw - 375px) / (1024 - 375)));;
        }
    }

    .left-overlay{
        left: 0;
        background: linear-gradient(91.24deg, #6FAAFF 0.96%, rgba(111, 170, 255, 0) 98.85%), linear-gradient(86.89deg, #6FAAFF 2.82%, rgba(111, 170, 255, 0) 100.24%);
    }

    .right-overlay{
        right: 0;
        background: linear-gradient(86.89deg, #6FAAFF 2.82%, rgba(111, 170, 255, 0) 100.24%), linear-gradient(86.89deg, #6FAAFF 2.82%, rgba(111, 170, 255, 0) 100.24%);
        transform: rotate(-180deg);
    }

    .carousel-slide-item__body {
        bottom: -2.5rem;
        height: 10%;
        position: absolute;

        h4 {
            margin: 0.7rem 0 0;
            text-transform: uppercase;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.3;
            margin: 0.7rem 0 0;
        }
    }
`

export {
    WrapSection2
}