import React from 'react'

// css
import { WrapSection3 } from './index.styles'

// component
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'

export default function Section3() {
  return (
    <WrapSection3>
      <Page1 />
      <Page2 />
      <Page3 />
    </WrapSection3>
  )
}
