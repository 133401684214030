import React from 'react'

const IcTiktok = (props) => {
    return (
        <svg {...props} width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_5020_94382)">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.0806 10.0386C19.1021 10.5563 14.739 13.2657 12.0886 17.4854C8.33061 23.4684 9.03198 31.3702 13.7831 36.5755C16.4125 39.4563 19.7008 41.1786 23.6443 41.7403C24.3805 41.8451 26.8271 41.8438 27.5958 41.7382C31.1973 41.243 34.4228 39.6456 36.876 37.1424C37.6811 36.3208 37.8526 36.1243 38.3866 35.4121C39.978 33.2896 41.0199 30.7022 41.4219 27.8743C41.526 27.1417 41.526 24.6837 41.4219 23.9515C41.1537 22.0663 40.6661 20.4602 39.8837 18.8849C39.0548 17.2159 38.2042 16.026 36.8802 14.6835C34.4241 12.193 31.3489 10.6459 27.8136 10.1221C27.0502 10.0089 24.8409 9.95961 24.0806 10.0386ZM28.9953 17.1177C29.1316 18.1763 29.4675 18.9977 30.053 19.7046C30.8152 20.6248 31.945 21.218 33.1757 21.3442L33.5696 21.3846V22.8563V24.3281L32.9825 24.2851C32.6595 24.2615 32.2378 24.2095 32.0455 24.1695C31.1972 23.9933 30.114 23.5329 29.3882 23.0401C29.1933 22.9078 29.0203 22.7995 29.0039 22.7995C28.9874 22.7995 28.9788 24.4458 28.9849 26.4577C28.9958 30.0797 28.9945 30.1215 28.8539 30.6763C28.3387 32.7093 26.7795 34.29 24.7644 34.822C24.0172 35.0194 22.9329 35.0494 22.1813 34.8936C19.9355 34.428 18.1939 32.7146 17.7317 30.5161C17.6112 29.9433 17.6138 28.8354 17.7368 28.2297C17.8445 27.6998 18.1401 26.9627 18.4588 26.4292C18.7318 25.9722 19.4944 25.1621 19.9679 24.826C21.0517 24.0568 22.523 23.6426 23.7376 23.7647L24.1732 23.8085V25.3227V26.8369L23.9414 26.7728C23.8139 26.7376 23.4568 26.7204 23.148 26.7346C22.4363 26.7673 21.9018 27.0029 21.4141 27.4987C19.8643 29.0745 20.7463 31.694 22.9411 32.034C24.3744 32.2559 25.7693 31.2026 25.9759 29.7423C26.0133 29.4783 26.0381 26.846 26.0388 23.0642L26.0401 16.8219H27.4986H28.9571L28.9953 17.1177Z" fill="#85E0FF" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.0806 10.0386C19.1021 10.5563 14.739 13.2657 12.0886 17.4854C8.33061 23.4684 9.03198 31.3702 13.7831 36.5755C16.4125 39.4563 19.7008 41.1786 23.6443 41.7403C24.3805 41.8451 26.8271 41.8438 27.5958 41.7382C31.1973 41.243 34.4228 39.6456 36.876 37.1424C37.6811 36.3208 37.8526 36.1243 38.3866 35.4121C39.978 33.2896 41.0199 30.7022 41.4219 27.8743C41.526 27.1417 41.526 24.6837 41.4219 23.9515C41.1537 22.0663 40.6661 20.4602 39.8837 18.8849C39.0548 17.2159 38.2042 16.026 36.8802 14.6835C34.4241 12.193 31.3489 10.6459 27.8136 10.1221C27.0502 10.0089 24.8409 9.95961 24.0806 10.0386ZM28.9953 17.1177C29.1316 18.1763 29.4675 18.9977 30.053 19.7046C30.8152 20.6248 31.945 21.218 33.1757 21.3442L33.5696 21.3846V22.8563V24.3281L32.9825 24.2851C32.6595 24.2615 32.2378 24.2095 32.0455 24.1695C31.1972 23.9933 30.114 23.5329 29.3882 23.0401C29.1933 22.9078 29.0203 22.7995 29.0039 22.7995C28.9874 22.7995 28.9788 24.4458 28.9849 26.4577C28.9958 30.0797 28.9945 30.1215 28.8539 30.6763C28.3387 32.7093 26.7795 34.29 24.7644 34.822C24.0172 35.0194 22.9329 35.0494 22.1813 34.8936C19.9355 34.428 18.1939 32.7146 17.7317 30.5161C17.6112 29.9433 17.6138 28.8354 17.7368 28.2297C17.8445 27.6998 18.1401 26.9627 18.4588 26.4292C18.7318 25.9722 19.4944 25.1621 19.9679 24.826C21.0517 24.0568 22.523 23.6426 23.7376 23.7647L24.1732 23.8085V25.3227V26.8369L23.9414 26.7728C23.8139 26.7376 23.4568 26.7204 23.148 26.7346C22.4363 26.7673 21.9018 27.0029 21.4141 27.4987C19.8643 29.0745 20.7463 31.694 22.9411 32.034C24.3744 32.2559 25.7693 31.2026 25.9759 29.7423C26.0133 29.4783 26.0381 26.846 26.0388 23.0642L26.0401 16.8219H27.4986H28.9571L28.9953 17.1177Z" fill="white" />
            <defs>
                <filter id="filter0_f_5020_94382" x="0.136369" y="0.454545" width="50.9091" height="50.909" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4.77273" result="effect1_foregroundBlur_5020_94382" />
                </filter>
            </defs>
        </svg>
    )
}

export default IcTiktok