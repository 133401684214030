import React from 'react'

export default function IcArrowCircle(props) {
  return (
    <svg {...props} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27 49.5C39.4264 49.5 49.5 39.4264 49.5 27C49.5 14.5736 39.4264 4.5 27 4.5C14.5736 4.5 4.5 14.5736 4.5 27C4.5 39.4264 14.5736 49.5 27 49.5Z" fill="#212121" fillOpacity="0.4"/>
        <path d="M27 18L18 27L27 36" stroke="#FBFBFD" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36 27H18" stroke="#FBFBFD" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
