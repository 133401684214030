import styled from "styled-components"
import { WrapSection } from "../index.styles"
//img
import bg_Section3 from '../../assets/images/PNG/bg_Section3.png'


const WrapSection3 = styled(WrapSection)`
    background-image:url(${bg_Section3});
    background-size:100%;
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
    height: fit-content;
`

export {
    WrapSection3
}