import React, { useEffect, useRef } from 'react'

// css
import { WrapPage1, Bound } from './index.styles'

//img
import text_section3 from '../../../assets/images/PNG/text_section3.png'
import text_section3_page1_tablet from '../../../assets/images/PNG/text_section3_page1_tablet.png'
import buble1 from '../../../assets/images/PNG/buble1.png'
import buble2 from '../../../assets/images/PNG/buble2.png'
import buble3 from '../../../assets/images/PNG/buble3.png'
import buble4 from '../../../assets/images/PNG/buble4.png'
import device_banuba from '../../../assets/images/PNG/device_banuba.png'
import gif_terminal  from '../../../assets/images/PNG/gif_terminal.gif'

let isBubble1Appear = false
let isBubble2Appear = false
let isBubble3Appear = false
let isBubble4Appear = false

const DELTA_APPEAR = 100
const DELTA_DISAPPEAR = 50

export default function Page1() {
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  const boundRef = useRef(null)
  const titleRef = useRef(null)
  const bubble1Ref = useRef(null)
  const bubble2Ref = useRef(null)
  const bubble3Ref = useRef(null)
  const bubble4Ref = useRef(null)

  useEffect(() => {
    _renderUI()
    window.addEventListener("resize", _handleResize)
    window.addEventListener("scroll", _handleScroll)
    return () => {
      window.removeEventListener("resize", _handleResize)
      window.removeEventListener("scroll", _handleScroll)
    }
  }, [])

  const _handleResize = (e) => {
    _renderUI()
  }

  const _renderUI = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    if(!titleRef.current) return
    if(vw <= 939){
        titleRef.current.src = `${text_section3_page1_tablet}`
    }
    else{
        titleRef.current.src = `${text_section3}`
    }
  }

  const _handleScroll = (e) => {
    let boundRect = boundRef.current.getBoundingClientRect()
    let bubble1Rect = bubble1Ref.current.getBoundingClientRect()
    let bubble2Rect = bubble2Ref.current.getBoundingClientRect()
    let bubble3Rect = bubble3Ref.current.getBoundingClientRect()
    let bubble4Rect = bubble4Ref.current.getBoundingClientRect()
    // bubble 1
    if (!isBubble1Appear) {
      if (vh - bubble1Rect.bottom >= DELTA_APPEAR) {
        bubble1Ref.current.classList.add('active')
        isBubble1Appear = true
      }
    }
    else {
      if (bubble1Rect.top - vh >= DELTA_DISAPPEAR) {
        bubble1Ref.current.classList.remove('active')
        isBubble1Appear = false
      }
    }
    // bubble 2
    if (!isBubble2Appear) {
      if (vh - bubble2Rect.bottom >= DELTA_APPEAR) {
        bubble2Ref.current.classList.add('active')
        isBubble2Appear = true
      }
    }
    else {
      if (bubble2Rect.top - vh >= DELTA_DISAPPEAR) {
        bubble2Ref.current.classList.remove('active')
        isBubble2Appear = false
      }
    }
    // bubble 3
    if (!isBubble3Appear) {
      if (vh - bubble3Rect.bottom >= DELTA_APPEAR) {
        bubble3Ref.current.classList.add('active')
        isBubble3Appear = true
      }
    }
    else {
      if (bubble3Rect.top - vh >= DELTA_DISAPPEAR) {
        bubble3Ref.current.classList.remove('active')
        isBubble3Appear = false
      }
    }
    // bubble 4
    if (!isBubble4Appear) {
      if (vh - bubble4Rect.bottom >= DELTA_APPEAR) {
        bubble4Ref.current.classList.add('active')
        isBubble4Appear = true
      }
    }
    else {
      if (bubble4Rect.top - vh >= DELTA_DISAPPEAR) {
        bubble4Ref.current.classList.remove('active')
        isBubble4Appear = false
      }
    }
  }

  return (
    <WrapPage1>
      <Bound ref={boundRef}>
        <div className='title'>
          <img className='block-title-sect3' src={text_section3} alt="text" ref={titleRef}/>
        </div>
        <div className='device'>
          <img className='device-banuba' src={gif_terminal} alt="banuba" />
        </div>
        <div className='bubble-1'>
          <div className='bubble' ref={bubble1Ref}>
            <img src={buble1} alt="img" />
          </div>
        </div>
        <div className='bubble-2'>
          <div className='bubble' ref={bubble2Ref}>
            <img src={buble2} alt="img" />
          </div>
        </div>
        <div className='bubble-3'>
          <div className='bubble' ref={bubble3Ref}>
            <img src={buble3} alt="img" />
          </div>
        </div>
        <div className='bubble-4'>
          <div className='bubble' ref={bubble4Ref}>
            <img src={buble4} alt="img" />
          </div>
        </div>
      </Bound>
    </WrapPage1>
  )
}
