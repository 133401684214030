import React from 'react'

// css
import { WrapMain } from './index.styles'

// component
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

// test

export default function Main() {
  return (
    <WrapMain>
        <Section1 />
        <Section2 />
        <Section3 />
    </WrapMain>
  )
}
