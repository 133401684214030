import React from 'react'

// css
import { WrapHeader } from './index.styles'

//image
import LogoFacepay from '../../assets/images/SVG/LogoFacepay'

export default function Header() {
  return (
    <WrapHeader>
      <LogoFacepay />
    </WrapHeader>
  )
}
