import styled from "styled-components"
import { WrapSection } from "../../index.styles"

const WrapSection2 = styled(WrapSection)`
    height: fit-content;
    .wrap-image-content{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        width: 100%;
        height: 100%;
        .image-bound{
            width: 100%;
            height: 100vh;
            position: relative;
            /* transition-property: width;
            transition-duration: 500ms;
            transition-timing-function: linear; */
            img, .image, .gif {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                object-fit: cover;
            }
            .text{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: unset;
                opacity: 1;
                z-index: 2;
            }
            .gif{
                opacity: 1;
            }
            :hover{
                /* width: 288px; */
                .image{
                    opacity: 0;        
                }
                .gif{
                    opacity: 1; 
                    .text{                       
                        z-index: 2;
                    }  
                }
            }
        }
    }
    
    @media only screen and (min-width: 426px) {
        .wrap-image-content{
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: 1fr;
            .image-bound{
                img, .image, .gif {
                    
                }
                .text{
                    opacity: 1;
                    z-index: 0;
                }
                .gif{
                    opacity: 0;
                }
                :hover{
                    width: 288px;
                    .image{
                        opacity: 0;        
                    }
                    .gif{
                        opacity: 1; 
                        .text{                       
                            z-index: 2;
                        }  
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .wrap-image-content{
        
            .image-bound{
                :hover{
                    width: 100%;
                }
            }
    }
`

export {
    WrapSection2
}