import React from 'react'

const IcLinkedin = (props) => {
    return (
        <svg {...props} width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_5020_94388)">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.7169 10.0386C19.7385 10.5563 15.3754 13.2657 12.7249 17.4854C8.96698 23.4684 9.66835 31.3702 14.4195 36.5755C17.0488 39.4563 20.3372 41.1786 24.2807 41.7403C25.0169 41.8451 27.4635 41.8438 28.2321 41.7382C31.8337 41.243 35.0592 39.6456 37.5124 37.1424C38.3175 36.3208 38.489 36.1243 39.023 35.4121C40.6144 33.2896 41.6562 30.7022 42.0583 27.8743C42.1624 27.1417 42.1624 24.6837 42.0582 23.9515C41.79 22.0663 41.3024 20.4602 40.5201 18.8849C39.6912 17.2159 38.8406 16.026 37.5165 14.6835C35.0605 12.193 31.9853 10.6459 28.4499 10.1221C27.6866 10.0089 25.4772 9.95961 24.7169 10.0386ZM20.6092 16.9214C21.0501 17.0761 21.5784 17.6154 21.7324 18.0679C22.0922 19.1256 21.6019 20.2432 20.616 20.6123C19.4544 21.0472 18.2686 20.686 17.7434 19.7372C17.5747 19.4324 17.56 19.3562 17.56 18.7833C17.56 18.2607 17.5821 18.1195 17.6971 17.9043C17.9897 17.3571 18.4201 17.0105 19.018 16.8408C19.3846 16.7367 20.2009 16.7781 20.6092 16.9214ZM32.4325 22.2733C33.0634 22.4447 33.7576 22.8462 34.206 23.2992C34.6857 23.7836 34.9723 24.2475 35.2134 24.929C35.5592 25.9069 35.575 26.1409 35.575 30.2798V34.0699H33.646H31.7169L31.7158 30.6608C31.715 27.9698 31.6963 27.1645 31.6271 26.8379C31.4666 26.0804 31.1632 25.6432 30.6095 25.3716C30.1161 25.1295 29.4206 25.1248 28.9166 25.3601C28.4681 25.5695 28.0945 25.9536 27.8438 26.463L27.641 26.8752L27.6209 30.4725L27.6009 34.0699H25.6763H23.7517V28.2168V22.3637H25.6808H27.6098L27.6118 23.1887L27.6138 24.0138L27.7715 23.827C27.8582 23.7242 28.0386 23.5097 28.1724 23.3502C28.6235 22.8126 29.5229 22.3207 30.3179 22.1768C30.7582 22.0972 31.9913 22.1534 32.4325 22.2733ZM21.6207 28.2324L21.6366 34.0699H19.6761H17.7156V28.2583C17.7156 25.0619 17.7346 22.4276 17.758 22.4043C17.7813 22.381 18.6564 22.3693 19.7026 22.3784L21.6049 22.3948L21.6207 28.2324Z" fill="#85E0FF" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.7169 10.0386C19.7385 10.5563 15.3754 13.2657 12.7249 17.4854C8.96698 23.4684 9.66835 31.3702 14.4195 36.5755C17.0488 39.4563 20.3372 41.1786 24.2807 41.7403C25.0169 41.8451 27.4635 41.8438 28.2321 41.7382C31.8337 41.243 35.0592 39.6456 37.5124 37.1424C38.3175 36.3208 38.489 36.1243 39.023 35.4121C40.6144 33.2896 41.6562 30.7022 42.0583 27.8743C42.1624 27.1417 42.1624 24.6837 42.0582 23.9515C41.79 22.0663 41.3024 20.4602 40.5201 18.8849C39.6912 17.2159 38.8406 16.026 37.5165 14.6835C35.0605 12.193 31.9853 10.6459 28.4499 10.1221C27.6866 10.0089 25.4772 9.95961 24.7169 10.0386ZM20.6092 16.9214C21.0501 17.0761 21.5784 17.6154 21.7324 18.0679C22.0922 19.1256 21.6019 20.2432 20.616 20.6123C19.4544 21.0472 18.2686 20.686 17.7434 19.7372C17.5747 19.4324 17.56 19.3562 17.56 18.7833C17.56 18.2607 17.5821 18.1195 17.6971 17.9043C17.9897 17.3571 18.4201 17.0105 19.018 16.8408C19.3846 16.7367 20.2009 16.7781 20.6092 16.9214ZM32.4325 22.2733C33.0634 22.4447 33.7576 22.8462 34.206 23.2992C34.6857 23.7836 34.9723 24.2475 35.2134 24.929C35.5592 25.9069 35.575 26.1409 35.575 30.2798V34.0699H33.646H31.7169L31.7158 30.6608C31.715 27.9698 31.6963 27.1645 31.6271 26.8379C31.4666 26.0804 31.1632 25.6432 30.6095 25.3716C30.1161 25.1295 29.4206 25.1248 28.9166 25.3601C28.4681 25.5695 28.0945 25.9536 27.8438 26.463L27.641 26.8752L27.6209 30.4725L27.6009 34.0699H25.6763H23.7517V28.2168V22.3637H25.6808H27.6098L27.6118 23.1887L27.6138 24.0138L27.7715 23.827C27.8582 23.7242 28.0386 23.5097 28.1724 23.3502C28.6235 22.8126 29.5229 22.3207 30.3179 22.1768C30.7582 22.0972 31.9913 22.1534 32.4325 22.2733ZM21.6207 28.2324L21.6366 34.0699H19.6761H17.7156V28.2583C17.7156 25.0619 17.7346 22.4276 17.758 22.4043C17.7813 22.381 18.6564 22.3693 19.7026 22.3784L21.6049 22.3948L21.6207 28.2324Z" fill="white" />
            <defs>
                <filter id="filter0_f_5020_94388" x="0.772737" y="0.454545" width="50.9091" height="50.909" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4.77273" result="effect1_foregroundBlur_5020_94388" />
                </filter>
            </defs>
        </svg>
    )
}

export default IcLinkedin