import styled from "styled-components"
import { WrapSection } from "../../index.styles"

const WrapPage3 = styled(WrapSection)`
    justify-content: center;
    height: 100%;
    width: calc(100% - (calc(28px + (104 - 28) * ((100vw - 375px) / (1440 - 375)))*2));
    padding: 0 calc(28px + (104 - 28) * ((100vw - 375px) / (1440 - 375)));
    padding-top: calc(32px + (64 - 32) * ((100vw - 375px) / (1440 - 375)));
    .block-title-sect5{
        width: calc(277px + (596 - 277) * ((100vw - 375px) / (768 - 375)));
    }
    .wrap-logo-bank{
        width: 320px;
        height: fit-content;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 4px 4px 6px rgba(51, 74, 186, 0.35);
        border-radius: 24px;
        padding: 24px;
        box-sizing: border-box;
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 40px 32px;       
        .logo-bank{
            width: 120px;
            height: 48px;
        }
    }
    .block-control{
        display: flex;
        flex-direction: column;
        margin-top: 116px;
        .wrap-badge{
            display: flex;
            align-items: center;
            gap: 18px;
            margin-bottom: 32px;
            img{
                width: calc(163px + (213 - 163) * ((100vw - 375px) / (768 - 375)));
                height: calc(48px + (63 - 48) * ((100vw - 375px) / (768 - 375)));
                object-fit: contain;
                cursor: pointer;
            }
        }
        .btn-view{
            width: 421px;
            height: 113px;
            cursor: pointer;
        }
    }
    .block-link{
        display: flex;
        flex-direction: column;
        margin: 56px 0 52px 0;
        .title-link{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: #FFFFFF;
            margin: 0 0 16px 0;
        }
    }

    @media only screen and (min-width: 528px) {
        .wrap-logo-bank{
            width: 472px;
            grid-template-columns: repeat(3, auto);
        }
    }
    
    @media only screen and (min-width: 680px) {
        .wrap-logo-bank{
            width: 624px;
            grid-template-columns: repeat(4, auto);
        }
    }

    @media only screen and (min-width: 769px) {
        .block-title-sect5{
            width: calc(700px + (1181 - 700) * ((100vw - 768px) / (1440 - 768)));
        }
        .block-control{
            .wrap-badge{
                img{
                    width: 213px;
                    height: 63px;
                }
            }
        }
    }

    @media only screen and (min-width: 832px) {
        .wrap-logo-bank{
            width: 776px;
            grid-template-columns: repeat(5, auto);
        }
    }

    @media only screen and (min-width: 984px) {
        .wrap-logo-bank{
            width: 928px;
            grid-template-columns: repeat(6, auto);
        }
    }

    @media only screen and (min-width: 1136px) {
        .wrap-logo-bank{
            width: 1080px;
            grid-template-columns: repeat(7, auto);
        }
    }

    @media only screen and (min-width: 1288px) {
        .wrap-logo-bank{
            width: 1228px;
            grid-template-columns: repeat(8, auto);
        }
    }

    @media only screen and (min-width: 1440px) {
        .block-title-sect5{
            width: 1181px;
        }
    }
`

export {
    WrapPage3
}