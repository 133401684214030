export const dataPage2Section3 = [
    {
        text1: "Từ ngày",
        text2: "thanh toán Facepay tại GS25 với hóa đơn từ",
        span1: "01/03 đến 30/05/2023",
        span2: "40k được giảm 10k",
    },
    {
        text1: "Áp dụng mỗi tài khoản",
        text2: "",
        span1: "1 lần/ngày",
        span2: "",
    },
    {
        text1: "Áp dụng tối đa",
        text2: "",
        span1: "1000 giao dịch/ngày",
        span2: "",
    },
    {
        text1: "Chương trình có thể kết thúc sớm hơn dự kiến khi hết ngân sách khuyến mãi",
        text2: "",
        span1: "",
        span2: "",
    }
]