import logo from './logo.svg';

// component
import Main from './ui';

function App() {
  return (
    <Main />
  );
}

export default App;
