import styled from "styled-components"
import { WrapSection } from "../index.styles"
//img
import bg_Section1 from '../../assets/images/PNG/bg_Section1.png'

const WrapSection1 = styled(WrapSection)`
    background-image:url(${bg_Section1});
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
    position: relative;
    height: fit-content;
    .wrap-content{
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 1fr;
        padding: 0 calc(24px + (75 - 24) * ((100vw - 375px) / (1440 - 375)));
        padding-top: calc(168px + (144 - 168) * ((100vw - 375px) / (425 - 375)));
        width: calc(100% - ((24px + (75 - 24) * ((100vw - 375px) / (1440 - 375)))*2));
        height: calc(100% - 150px);
        grid-row-gap: 11px;
        .block-title{
            margin-left: -8px;
            width: calc(293px + (633 - 293) * ((100vw - 375px) / (1440 - 375)));
            min-width: 293px;
        }
        .block-control{
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            align-items: start;
            .wrap-badge{
                display: flex;
                gap: 13px;
                margin-bottom: 64px;
                flex-direction: column;
                align-items: start;
                img{
                    height: 48px;
                    object-fit: contain;
                    cursor: pointer;
                }
            }
            .btn-view{
                width: 327px;
                height: 88px;
                cursor: pointer;
            }
        }
        .block-slide-image{
            grid-row: 2 / span 1;
            width: 100%;
            height: calc(443px + (480 - 443) * ((100vw - 425px) / (768 - 425)));
            .slide-bar{
                position: relative;
                width: 100%;
                height: 100%;
                img, .image {
                    position: absolute;
                    height: 100%;
                    opacity: 0;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    object-fit: cover;
                    transition-property: opacity;
                    transition-duration: 500ms;
                    transition-timing-function: linear;
                }
                .active {
                    opacity: 1;
                }
                .inactive{
                    opacity: 0;
                }
            }
        }
    }

    @media only screen and (min-width: 371px) {
        .wrap-content{
        
            .block-control{
                align-items: center;
                .wrap-badge{
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
    }

    @media only screen and (min-width: 425px) {
        .wrap-content{
            padding: 0 calc(24px + (60 - 24) * ((100vw - 425px) / (1440 - 425)));
            padding-top: 144px;
            width: calc(100% - ((24px + (60 - 24) * ((100vw - 425px) / (1440 - 425)))*2));
            height: 100%;
            grid-row-gap: 11px;
            justify-items: center;
            .block-title{
                width: 100%;
            }
            .block-control{
                .wrap-badge{
                    display: flex;
                    align-items: center;
                    gap: 13px;
                    margin-bottom: 64px;
                    img{
                        height: calc(48px + (63 - 48) * ((100vw - 425px) / (1023 - 425)));
                    }
                }
                .btn-view{
                    
                }
            }
            .block-slide-image{
                height: calc(443px + (760 - 443) * ((100vw - 425px) / (1023 - 425)));
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .wrap-content{
            justify-items: unset;
            .block-title{
                margin-left: -15px;
                width: 100%;
                max-width: 663px;
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        height: 100vh;
        .wrap-content{
            grid-template-rows: auto 1fr;
            grid-template-columns: repeat(2, 1fr);
            padding: 150px 0 0 0;
            padding-left: calc(60px + (75 - 60) * ((100vw - 1024px) / (1440 - 1024)));
            width: calc(100% - (60px + (75 - 60) * ((100vw - 1024px) / (1440 - 1024))));
            max-width: calc(1440px - 75px);
            max-height: calc(100vh - 150px);
            grid-row-gap: 20px;
            grid-column-gap: 48px;
            .block-title{
                
            }
            .block-control{
                margin: 48px 0 0 0;
                align-items: start;
                .wrap-badge{
                    margin-bottom: 0;
                    img{
                        height: 63px;
                    }
                }
            }
            .block-slide-image{
                grid-row: 1 / span 2;
                grid-column: 2;
                height: 100%;
                img, .image {
                    left:0;
                    transform: unset;
                    height: 100%;
                }
            }
        }
    }
    @media only screen and (min-width: 1440px) {
        .wrap-content{
            width: calc(100% - 75px);
            padding-left: 75px;
        }
    }
`

export {
    WrapSection1
}