// image
import hanh_phuc from '../../assets/images/PNG/hanh_phuc.png'
import gian_giu from '../../assets/images/PNG/gian_giu.png'
import thu_vi from '../../assets/images/PNG/thu_vi.png'
import bun_ba from '../../assets/images/PNG/bun_ba.png'
import hai_long from '../../assets/images/PNG/hai_long.png'

import hanh_phuc_gif from '../../assets/images/PNG/hanh_phuc.gif'
import gian_giu_gif from '../../assets/images/PNG/gian_giu.gif'
import thu_vi_gif from '../../assets/images/PNG/thu_vi.gif'
import bun_ba_gif from '../../assets/images/PNG/bun_ba.gif'
import hai_long_gif from '../../assets/images/PNG/hai_long.gif'

import text_hp from '../../assets/images/PNG/text_hp.png'
import text_gd from '../../assets/images/PNG/text_gd.png'
import text_tv from '../../assets/images/PNG/text_tv.png'
import text_bb from '../../assets/images/PNG/text_bb.png'
import text_hl from '../../assets/images/PNG/text_hl.png'

export const data = [
    {
        card: {
            key: "image-1",
            image: hanh_phuc,
            imageGif: hanh_phuc_gif,
            text: text_hp,
            color: 'red',
            gifColor: 'rgba(255, 0, 0, 0.5)'
        }
    },
    {
        card: {
            key: "image-2",
            image: gian_giu,
            imageGif: gian_giu_gif,
            text: text_gd,
            color: 'green',
            gifColor: 'rgba(0, 128, 0, 0.5)'
        }
    },
    {
        card: {
            key: "image-3",
            image: thu_vi,
            imageGif: thu_vi_gif,
            text: text_tv,
            color: 'blue',
            gifColor: 'rgba(0, 0, 255, 0.5)'
        }
    },
    {
        card: {
            key: "image-4",
            image: bun_ba,
            imageGif: bun_ba_gif,
            text: text_bb,
            color: 'yellow',
            gifColor: 'rgba(255, 255, 0, 0.5)'
        }
    },
    {
        card: {
            key: "image-4",
            image: hai_long,
            imageGif: hai_long_gif,
            text: text_hl,
            color: 'purple',
            gifColor: 'rgba(128, 0, 128, 0.5)'
        }
    }
]