import React, { useEffect, useState } from 'react'

import Laptop from './Laptop'
import Tablet from './Tablet'

export default function Section2() {

    const [isTablet, setIsTablet] = useState(false)

    useEffect(() => {
        _renderUI()
        window.addEventListener("resize", _handleResize)
        return () => {
            window.removeEventListener("resize", _handleResize)
        }
    }, [])

    const _handleResize = (e) => {
        _renderUI()
    }

    const _renderUI = () => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if(vw > 425 && vw < 1024) setIsTablet(true)
        else setIsTablet(false)
    }
    return (
        <>
            {
                isTablet ?
                <Tablet />
                :
                <Laptop />
            }
        </>
    )
}
