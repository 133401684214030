import styled from "styled-components"
import { WrapSection } from "../../index.styles"

const WrapPage1 = styled(WrapSection)`
    height: fit-content;
`

const Bound = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 250px 168px 154px 542px;
    grid-row-gap: 32px;
    grid-column-gap: 5px;
    .title{
        grid-column: 1 / span 2;
        display: flex;
        justify-content: center;
        align-items: end;
        .block-title-sect3{
            width: calc(292px + (392 - 292) * ((100vw - 375px) / (940 - 375)));
        }
    }
    .device{
        grid-row: 4 / span 1;
        grid-column: 1 / span 2;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img{
            width: 288px;
            height: 542px;
            object-fit: cover;
        }
    }
    .bubble-1, .bubble-2, .bubble-3, .bubble-4{
        position: relative;
        .bubble{
            position: absolute;
            opacity: 0;
            transition-property: opacity;
            transition-duration: 500ms;
            transition-timing-function: linear;
            animation-duration: 800ms;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .active{
            opacity: 1;
        }
    }
    .bubble-1{
        .bubble{
            width: 231px;
            height: 270px;
            top: 0;
            right: -26px;
        }
        .active{
            animation-name: bubble1-mobile-appear;
        }
    }
    .bubble-2{
        .bubble{
            width: calc(174px + (206 - 174) * ((100vw - 375px) / (425 - 375)));
            height: 242px;
            top: 0;
            left: 9px;
        }
        .active{
            animation-name: bubble2-mobile-appear;
        }
    }
    .bubble-3{
        .bubble{
            width: 118px;
            height: 140px;
            top: 0;
            right: 33px;
        }
        .active{
            animation-name: bubble3-mobile-appear;
        }
    }
    .bubble-4{
        .bubble{
            width: 194px;
            height: 211px;
            top: 0;
            left: -20px;
        }
        .active{
            animation-name: bubble4-mobile-appear;
        }
    }

    @media only screen and (min-width: 425px) {
        .bubble-2{
            .bubble{
                width: 206px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 340px 1fr;
        grid-template-rows: minmax(196px, 270px) 1fr 211px;
        .title{
            grid-column: 1 / span 3;
            
        }
        .device{
            grid-row: 2 / span 2;
            grid-column: 2 / span 1;
            img{
                width: 340px;
                height: 616px;
            }
        }
        .bubble-1{
            .bubble{
                top: unset;
                bottom: 55px;
                right: -19px;
            }
            .active{
                animation-name: bubble1-tablet-appear;
            }
        }
        .bubble-2{
            .bubble{
                top: unset;
                bottom: 2px;
                left: -3px;
            }
            .active{
                animation-name: bubble2-tablet-appear;
            }
        }
        .bubble-3{
            .bubble{
                top: unset;
                bottom: 67px;
                right: 5px;
            }
            .active{
                animation-name: bubble3-tablet-appear;
            }
        }
        .bubble-4{
            .bubble{
                top: unset;
                bottom: 0px;
                left: -8px;
            }
            .active{
                animation-name: bubble4-tablet-appear;
            }
        }
    }

    @media only screen and (min-width: 940px) {
        .title{
            .block-title-sect3{
                width: calc(700px + (991 - 700) * ((100vw - 940px) / (1440 - 940)));
            }
        }
        .bubble-1{
            .bubble{
                width: 231px;
                height: 270px;
                bottom: 55px;
                right: 57px;
            }
            .active{
                animation-name: bubble1-appear;
            }
        }
        .bubble-2{
            .bubble{
                width: 206px;
                height: 242px;
                bottom: 2px;
                left: 16px;
            }
            .active{
                animation-name: bubble2-appear;
            }
        }
        .bubble-3{
            .bubble{
                width: 118px;
                height: 140px;
                bottom: 67px;
                right: 13px;
            }
            .active{
                animation-name: bubble3-appear;
            }
        }
        .bubble-4{
            .bubble{
                width: 194px;
                height: 211px;
                bottom: 0px;
                left: -20px;
            }
            .active{
                animation-name: bubble4-appear;
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        .title{
            .block-title-sect3{
                width: 991px;
            }
        }
    }

    @keyframes bubble1-appear {
        from {
            bottom: 45px;
            right: 47px;
        }
        to {
            bottom: 55px;
            right: 57px;
        }
    }
    @keyframes bubble1-tablet-appear {
        from {
            bottom: 45px;
            right: -29px;
        }
        to {
            bottom: 55px;
            right: -19px;
        }
    }
    @keyframes bubble1-mobile-appear {
        from {
            top: 10px;
            right: -36px;
        }
        to {
            top: 0;
            right: -26px;
        }
    }
    @keyframes bubble2-appear {
        from {
            bottom: -8px;
            left: 6px;
        }
        to {
            bottom: 2px;
            left: 16px;
        }
    }
    @keyframes bubble2-tablet-appear {
        from {
            bottom: -8px;
            left: -13px;
        }
        to {
            bottom: 2px;
            left: -3px;
        }
    }
    @keyframes bubble2-mobile-appear {
        from {
            top: 10px;
            left: -1px;
        }
        to {
            top: 0;
            left: 9px;
        }
    }
    @keyframes bubble3-appear {
        from {
            bottom: 57px;
            right: 3px;
        }
        to {
            bottom: 67px;
            right: 13px;
        }
    }
    @keyframes bubble3-tablet-appear {
        from {
            bottom: 57px;
            right: -5px;
        }
        to {
            bottom: 67px;
            right: 5px;
        }
    }
    @keyframes bubble3-mobile-appear {
        from {
            top: 10px;
            right: 23px;
        }
        to {
            top: 0;
            right: 33px;
        }
    }
    @keyframes bubble4-appear {
        from {
            bottom: -10px;
            left: -30px;
        }
        to {
            bottom: 0px;
            left: -20px;
        }
    }
    @keyframes bubble4-tablet-appear {
        from {
            bottom: -10px;
            left: -18px;
        }
        to {
            bottom: 0px;
            left: -8px;
        }
    }
    @keyframes bubble4-mobile-appear {
        from {
            top: 10px;
            left: -30px;
        }
        to {
            top: 0;
            left: -20px;
        }
    }
`

export {
    WrapPage1,
    Bound
}