import styled from "styled-components";

const WrapPopup = styled.div`
    position: relative;
   .ic_close{
        position: absolute;
        top: -50px;
        right: -50px;
        cursor: pointer;
   }
`
export { WrapPopup }